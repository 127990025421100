<template>
	<section class="license">
		<div class="container">
			<Breadcrumb :pages="pages" class="license__crumb" />

			<h1 class="license__title">{{ $t('license.cookies_title') }}</h1>
			<div class="license__items">
				<div class="license__item">
					<div v-html="$t('license.cookies_text')" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'

export default {
	name: 'Cookies',
	components: { Breadcrumb },
	computed: {}
}
</script>
